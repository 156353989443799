import React from "react";

import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";

import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

function UserMenu({ avatar }) {
	const anchor = React.useRef(null);
	const [open, setOpen] = React.useState(false);
	const classes = useStyles("Header");
	const { accountStore } = useStores();

	const toggle = () => setOpen(!open);
	const handleClose = () => setOpen(false);
	const handleListKeyDown = (event) => {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	};
	const handleLogout = (e) => {
		accountStore.logout();
	};

	return (
		<>
			<Tooltip
				enterDelay={400}
				title="Account"
				arrow
				TransitionComponent={Zoom}
				placement={"bottom"}
			>
				<ButtonBase
					ref={anchor}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={toggle}
					className={classes.profile}
				>
					<Box display={"flex"} alignItems={"center"} p={2}>
						<Box mr={1}>
							<Avatar src={avatar} style={{ width: 32, height: 32 }} />
						</Box>
						<ExpandMoreOutlinedIcon />
					</Box>
				</ButtonBase>
			</Tooltip>
			<Popper
				open={open}
				anchorEl={anchor.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 1500 }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						<Paper elevation={6} style={{ width: 240 }}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="menu-list-grow"
									onKeyDown={handleListKeyDown}
								>
									{/*<MenuItem component={"a"} href="/pros/reviews"*/}
									{/*          onClick={handleClose}>*/}
									{/*    <ListItemIcon className={classes.adminListItemIcon}>*/}
									{/*        <GradeOutlinedIcon/>*/}
									{/*    </ListItemIcon>*/}

									{/*    <ListItemText className={classes.adminListItemText}*/}
									{/*                  disableTypography*/}
									{/*                  primary={"Reviews"}/>*/}
									{/*</MenuItem>*/}
									<MenuItem
										component={"a"}
										href="/pros/settings/payments"
										onClick={handleClose}
									>
										<ListItemIcon className={classes.adminListItemIcon}>
											<PaymentIcon />
										</ListItemIcon>

										<ListItemText
											className={classes.adminListItemText}
											disableTypography
											primary={"Billing"}
										/>
									</MenuItem>
									<MenuItem
										component={"a"}
										href="/pros/settings"
										onClick={handleClose}
									>
										<ListItemIcon className={classes.adminListItemIcon}>
											<SettingsIcon />
										</ListItemIcon>

										<ListItemText
											className={classes.adminListItemText}
											disableTypography
											primary={"Settings"}
										/>
									</MenuItem>

									<MenuItem onClick={handleLogout}>
										<ListItemIcon className={classes.adminListItemIcon}>
											<ExitToAppIcon />
										</ListItemIcon>

										<ListItemText
											className={classes.adminListItemText}
											disableTypography
											primary={"Sign out"}
										/>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}

export default UserMenu;
