import React from "react";
import { observer } from "mobx-react";

import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";

import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import VolumeMuteOutlinedIcon from "@material-ui/icons/VolumeMuteOutlined";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import { onError } from "apollo-link-error";

const { BRAND_NAME } = process.env;

const NotificationsDropdown = observer(({ onError }) => {
	const anchor = React.useRef(null);
	const [open, setOpen] = React.useState(false);

	const { headerStore: store } = useStores();
	const { alerts } = store;

	const classes = useStyles("Header");

	const toggleDropwdown = () => {
		store.viewAlerts().catch(onError);
		setOpen(!open);
	};
	const handleClose = () => {
		setOpen(false);
		store.init().catch(onError);
	};

	const handleClickAlert = (notificationId) => () => {
		store
			.clickAlert(notificationId)
			.then(() => handleClose())
			.catch(onError);
	};

	const handleListKeyDown = (event) => {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	};

	const renderNotificationIcon = (type) => {
		switch (type) {
			case "leads":
				return <MessageOutlinedIcon />;
			case "review":
				return <StarBorderOutlinedIcon />;
			case "information":
				return <CreateOutlinedIcon />;
			case "financial":
				return <AttachMoneyOutlinedIcon />;
			case "calendar":
				return <CalendarTodayOutlinedIcon />;
			case "promotion":
				return <VolumeMuteOutlinedIcon />;
			default:
				return <AssignmentTurnedInOutlinedIcon />;
		}
	};

	return (
		<>
			<Tooltip
				//	enterDelay={200}
				title="Notifications"
				arrow
				TransitionComponent={Zoom}
				placement={"bottom"}
			>
				<IconButton
					color="secondary"
					aria-label="Notifications"
					className={classes.headerIconButton}
					ref={anchor}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={toggleDropwdown}
				>
					<Badge
						badgeContent={alerts.length}
						overlap="rectangular"
						color="error"
						classes={{
							anchorOriginTopRightRectangular: classes.bellBadge,
						}}
					>
						<NotificationsOutlinedIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popper
				open={open}
				anchorEl={anchor.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: 1500 }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						<Box maxWidth={{ xs: "100%", sm: 420 }} pl={{ xs: 4, sm: 0 }}>
							<Paper
								elevation={6}
								style={{ maxHeight: 420, overflowY: "auto" }}
							>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										autoFocusItem={open}
										id="menu-list-grow"
										onKeyDown={handleListKeyDown}
									>
										{!alerts.length && <div>You have no notifications.</div>}
										{alerts &&
											alerts.map((alert) => (
												<ListItem
													divider
													button
													alignItems={"flex-start"}
													component={"a"}
													href={alert.redirect_url}
													onClick={handleClickAlert(alert._id)}
													key={alert.id}
												>
													<ListItemIcon className={classes.adminListItemIcon}>
														{renderNotificationIcon(notification.type)}
													</ListItemIcon>

													<ListItemText
														classes={{
															primary: alert.clicked
																? classes.notificationAfterClick
																: classes.notificationBeforeClick,
															secondary: classes.notificationTextSecondary,
														}}
														className={alert.clicked ? "text-bold" : ""}
														primary={alert.message}
														secondary={alert.secondary_message}
													/>
												</ListItem>
											))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Box>
					</Grow>
				)}
			</Popper>
		</>
	);
});

export default NotificationsDropdown;
