import React, { useState } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";

import VolumeMuteOutlinedIcon from "@material-ui/icons/VolumeMuteOutlined";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import { onError } from "apollo-link-error";

import Drawer from "@material-ui/core/Drawer";
import { Onboarding } from "../Onboarding";

const HeaderSetup = observer(({ onError }) => {
	const anchor = React.useRef(null);
	const [open, setOpen] = React.useState(false);

	const { headerStore: store } = useStores();
	const { alerts } = store;

	const classes = useStyles("Header");

	// drawer

	const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setIsDrawerOpen(open);
	};

	return (
		<>
			<Box mr={{ xs: 0, sm: 4 }}>
				<Button
					variant="contained"
					disableElevation
					color="primary"
					//size="large"
					aria-label={`Complete Setup`}
					target="_blank"
					style={{ borderRadius: 999, padding: "5px 14px 5px 10px" }}
					onClick={toggleDrawer(true)}
					startIcon={
						<Box
							borderRadius={"50%"}
							fontSize={"12px !important"}
							bgcolor={"#2f81ee"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							color="#FFF"
							p={1.5}
							mr={1}
						>
							69%
						</Box>
					}
					endIcon={<KeyboardArrowRightOutlinedIcon />}
				>
					{`Complete setup`}
				</Button>
				<Drawer
					anchor="right"
					open={isDrawerOpen}
					onClose={toggleDrawer(false)}
					disableBackdropTransition={!iOS}
					disableDiscovery={iOS}
					ModalProps={{
						BackdropProps: {
							style: {
								backgroundColor: "rgba(182, 192, 205, 0.4)",
							},
						},
					}}
				>
					<Onboarding />
				</Drawer>
			</Box>
		</>
	);
});

export default HeaderSetup;
