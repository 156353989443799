import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { compose } from "recompose";
import get from "lodash/get";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ButtonBase from "@material-ui/core/ButtonBase";
// import Avatar from "@material-ui/core/Avatar";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import MenuIcon from "@material-ui/icons/Menu";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//import SearchIcon from "@material-ui/icons/Search";

import withConstants from "@framework/src/libs/withConstants";
import withStyles from "@framework/src/libs/withStyles";
import withStores from "@framework/src/libs/withStores";

import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";

import useConstants from "@hooks/useConstants";
import useStores from "@hooks/useStores";

import HeaderAlertDropdown from "./HeaderAlertDropdown";
import HeaderUserMenu from "./HeaderUserMenu";
//import theme from "@styles/theme";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { adjective as proAdjective } from "@language/provider";

import { styles, constants, stores } from "./.config";
import LogoIcon from "@components/Global/Custom/Logo/LogoIcon";
import RedeemIcon from "@material-ui/icons/Redeem";

import HeaderSetup from "./HeaderSetup";

const { LINK_HELP, REFERRAL_BONUS_AMOUNT, SITE_NAME, ENV } = process.env;

const Header = compose(
	withStyles(styles),
	withConstants(constants),
	withStores(stores, { setGlobal: true }),
	observer
)(({ toggleDrawer, toggleNav, toggleNotification }) => {
	const classes = useStyles("Header");
	const { iconHeight, iconWidth } = useConstants("HeaderViewModel");
	const { headerStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	useEffect(() => {
		store.init().then().catch(onError);
	}, []);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	return (
		<React.Fragment>
			{/* <Snackbar
				open={store.toggleNotification?.value && toggleNotification !== false}
				anchorOrigin={{
					horizontal: "center",
					vertical: isMobile ? "bottom" : "top",
				}}
			>
				<Alert
					severity="error"
					style={{
						boxShadow: "0 3px 10px rgb(0 0 0 / 15%)",
						border: "2px solid",
					}}
				>
					<Box fontWeight={600}>
						You’re not showing up in search results.{" "}
						<Link
							href={
								store.notificationData[store.toggleNotification?.reason]?.href
							}
							underline="always"
							color="primary"
							style={{ display: "inline-block" }}
						>
							{store.notificationData[store.toggleNotification?.reason]?.label}
						</Link>
					</Box>
				</Alert>
			</Snackbar> */}

			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.Toolbar}>
					<Box display={{ xs: "block", sm: "block", md: "none" }}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={toggleNav}
							style={{ borderRadius: 8 }}
						>
							<Box
								display={"flex"}
								alignItems={"center"}
								className={classes.iconWrap}
							>
								<LogoIcon width={iconWidth} height={iconHeight} />

								<ExpandMoreOutlinedIcon style={{ marginLeft: 4 }} />
							</Box>
						</IconButton>
					</Box>

					<Box display={{ xs: "none", sm: "none", md: "block" }}>
						<Tooltip
							enterDelay={400}
							title="Main menu"
							arrow
							TransitionComponent={Zoom}
							placement={"bottom"}
						>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={toggleDrawer}
								edge="start"
							>
								<MenuIcon />
							</IconButton>
						</Tooltip>
					</Box>

					<Box display={{ xs: "none", sm: "none", md: "block" }}>
						<ButtonBase
							href={"/pros"}
							edge="start"
							color="inherit"
							className={classes.profile}
						>
							<Box
								display={"flex"}
								alignItems={"center"}
								className={classes.iconWrap}
								p={"7px 12px 7px 8px"}
							>
								<LogoIcon width={iconWidth} height={iconHeight} />

								{store.provider && (
									<Box
										ml={3}
										display={{ xs: "none", md: "block" }}
										fontWeight={600}
										fontSize={16}
										overflow={"hidden"}
										className={classes.headerBizName}
										maxWidth={300}
									>
										{store.provider.company_name}
									</Box>
								)}
							</Box>
						</ButtonBase>
					</Box>

					<Box
						display={"flex"}
						flex={"1 1 auto"}
						alignItems={"center"}
						justifyContent={"space-between"}
						height={"100%"}
					>
						<Box
							width={"100%"}
							mr={{ xs: 0, sm: 10 }}
							display={"flex"}
							justifyContent={"center"}
						>
							{ENV === "production" && (
								<>
									{store.toggleNotification?.value &&
										toggleNotification !== false && (
											<Alert severity="error">
												{isMobile ? (
													<Box fontWeight={600}>
														<Link
															href={
																store.notificationData[
																	store.toggleNotification?.reason
																]?.href
															}
															underline="always"
															color="primary"
															style={{ display: "inline-block" }}
														>
															Set up your services
														</Link>
													</Box>
												) : (
													<Box fontWeight={600}>
														You’re not showing up in search results.{" "}
														<Link
															href={
																store.notificationData[
																	store.toggleNotification?.reason
																]?.href
															}
															underline="always"
															color="primary"
															style={{ display: "inline-block" }}
														>
															{
																store.notificationData[
																	store.toggleNotification?.reason
																]?.label
															}
														</Link>
													</Box>
												)}
											</Alert>
										)}
									{/*https://material-ui.com/components/autocomplete/#asynchronous-requests*/}

									{/* <Autocomplete
								classes={{
									root: classes.autocompleteRoot,
									input: classes.autocompleteInput,
									endAdornment: classes.autocompleteEndAdornment,
								}}
								options={customers}
								getOptionLabel={(option) => option.name}
								renderOption={(option) => (
									<React.Fragment>
										<Avatar
											alt={option.name}
											src="/static/images/avatar/1.jpg"
										/>
										<Box ml={3}>{option.name}</Box>
									</React.Fragment>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										className={classes.search}
										fullWidth
										placeholder="Search contacts"
										InputProps={{
											...params.InputProps,
											classes: {
												underline: classes.inputUnderline,
												root: classes.inputRoot,
												input: classes.inputInput,
												focused: classes.inputFocused,
											},
											startAdornment: (
												<InputAdornment position="start">
													<Tooltip
														enterDelay={400}
														title="Search"
														arrow
														TransitionComponent={Zoom}
														placement={"bottom"}
													>
														<IconButton size={"small"}>
															<SearchIcon />
														</IconButton>
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
								)}
							/> */}
								</>
							)}
						</Box>

						{/*<Box flexGrow={1}/>*/}

						<Box display={"flex"} alignItems={"center"}>
							{ENV === "development" && <HeaderSetup />}

							{store.REFERRAL_BONUS_AMOUNT &&
								["homeguide", "fash", "tutors", "lessons", "thervo"].includes(
									SITE_NAME
								) && (
									<Box display={{ xs: "none", sm: "none", md: "block" }}>
										<Tooltip
											//	enterDelay={200}
											title={`Refer ${proAdjective.plural}. Get $${store.REFERRAL_BONUS_AMOUNT}.`}
											arrow
											TransitionComponent={Zoom}
											placement={"bottom"}
										>
											<IconButton
												color="secondary"
												aria-label={`Refer pros. Get $${store.REFERRAL_BONUS_AMOUNT}.`}
												href={`/pros/referrals`}
												target={"_blank"}
												className={classes.headerIconButton}
											>
												<RedeemIcon />
											</IconButton>
										</Tooltip>
									</Box>
								)}
							<div>
								<HeaderAlertDropdown onError={onError} />
							</div>
							<Box display={"block"}>
								<Tooltip
									//enterDelay={200}
									title="Support"
									arrow
									TransitionComponent={Zoom}
									placement={"bottom"}
								>
									<IconButton
										color="secondary"
										aria-label="Support"
										href={LINK_HELP}
										target={"_blank"}
										className={classes.headerIconButton}
									>
										<HelpOutlineIcon />
									</IconButton>
								</Tooltip>
								{/* <Button
									variant="contained"
									disableElevation
									style={{
										backgroundColor: "#e7eaff",
										borderColor: "#e7eaff",
										color: "#0535bc",
									}}
									aria-label={`Get Help & Support`}
									href={LINK_HELP}
									target="_blank"
									startIcon={<HelpOutlineIcon />}
								>
									{`Support`}
								</Button> */}
							</Box>

							<Box display={{ xs: "none", sm: "none", md: "block" }} ml={1}>
								<HeaderUserMenu avatar={get(store.provider, "image.value")} />
							</Box>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>

			<ErrorTemplate />
		</React.Fragment>
	);
});

Header.defaultProps = {
	toggleNotification: null,
};

Header.propTypes = {};

export default Header;
