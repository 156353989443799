import { action, computed, observable, runInAction, toJS } from "mobx";
import rootService from "../@data/service";

const { getProviderByToken, getReferralConfiguration } = rootService;

class ProHeaderStore {
	@observable provider;
	@observable alerts = [];

	@observable REFERRAL_BONUS_AMOUNT;
	@observable REFERRAL_SPEND_THRESHOLD;

	notificationData = {
		profile: { href: "/pros/profile", label: "Finish profile" },
		targeting: { href: "/pros/services", label: "Set up targeting" },
		empty: { href: "", label: "" },
	};

	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	get toggleNotification() {
		if (!this.provider) return;

		const reason = this.provider.restrict_access?.reason.includes(
			"profile_incomplete"
		)
			? "profile"
			: "targeting";

		const value =
			!this.provider.all_preferences_set ||
			(this.provider.restrict_access?.value &&
				this.provider.restrict_access?.reason.includes("profile_incomplete"));
		return { value, reason };
	}

	@action
	async init() {
		try {
			const [provider, referralConfiguration] = await Promise.all([
				getProviderByToken({}, { batch: true }),
				getReferralConfiguration({}, { batch: true }),
			]);

			if (!provider) return;

			runInAction(() => {
				this.provider = provider;
				this.alerts = provider.alerts;
				this.REFERRAL_BONUS_AMOUNT =
					referralConfiguration.REFERRAL_BONUS_AMOUNT;
				this.REFERRAL_SPEND_THRESHOLD =
					referralConfiguration.REFERRAL_SPEND_THRESHOLD;
			});
		} catch (err) {
			throw err;
		}
	}

	async viewAlerts() {
		try {
			if (!this.alerts.length) return;
			const alertIds = this.alerts.map((alert) => alert._id);
			await updateProviderAlertsByToken({
				filter: { _operators: { _id: { in: alertIds } } },
				record: { viewed: true },
			});
		} catch (err) {
			throw new Error(err);
		}
	}

	async clickedAlert() {
		try {
			await updateProviderAlertByToken({
				record: { clicked: true },
			});
		} catch (err) {
			throw new Error(err);
		}
	}
}

export default ProHeaderStore;
