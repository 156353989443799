export default (theme) => ({
	root: {
		flexGrow: 1,
	},
	proTab: {
		"minWidth": 100,
		"padding": "19px 12px",
		"&:hover": {
			color: theme.palette.secondary.main,
		},
		[theme.breakpoints.down("md")]: {
			minWidth: 70,
		},
	},
	proTabSelected: {
		fontWeight: 700,
	},
	alertFixed: {
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 0,
		height: 70,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		color: theme.palette.secondary.main,
		backgroundColor: "#FFF",
		borderBottom: "1px solid #e8eaec",
		boxShadow: "none",
	},
	Toolbar: {
		paddingLeft: 16,
		paddingRight: 4,
		[theme.breakpoints.down("xs")]: {
			paddingRight: 0,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	bellBadge: {
		top: 2,
		right: 6,
	},
	tabBadge: {
		top: "inherit",
		right: "inherit",
		transform: "none",
		position: "relative",
	},
	title: {
		//flexGrow: 1,
		margin: "0px 20px 0px 0px",
	},
	logo: {
		width: "120px",
		display: "block",
	},
	iconWrap: {
		margin: 0,
	},
	icon: {
		width: "35px",
		borderRadius: "50%",
	},
	list: {
		width: 250,
	},
	listItem: {
		fontWeight: 600,
	},
	listIcon: {
		minWidth: "40px",
	},
	profile: {
		"borderRadius": 8,
		"&:hover": {
			background: "rgba(34, 34, 34, 0.04)",
		},
	},
	autocompleteRoot: {
		maxWidth: 550,
	},
	autocompleteInput: {
		padding: "14.5px 12px 14.5px 0px !important",
	},
	autocompleteEndAdornment: {
		right: 10,
	},
	adminListItemText: {
		fontWeight: 600,
	},
	adminListItemTextActive: {
		color: "#2586d9",
		fontWeight: 600,
	},
	adminListItemIcon: {
		minWidth: 40,
		color: theme.palette.secondary.main,
	},
	adminListItemIconActive: {
		minWidth: 40,
		color: "#2586d9",
	},
	search: {
		"position": "relative",
		"borderRadius": 8,
		"marginRight": 0,
		"marginLeft": 0,
		"width": "100%",
		"backgroundColor": "#f1f3f4",
		"&:hover": {
			backgroundColor: "#e7e7e7",
		},
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(6),
			marginRight: theme.spacing(4),
			//width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 3),
		color: "rgba(0,0,0,.4)",
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 1,
	},
	inputRoot: {
		color: "inherit",
		transition:
			"box-shadow .25s cubic-bezier(.23,1,.32,1),background-color .25s cubic-bezier(.23,1,.32,1),-webkit-box-shadow .25s cubic-bezier(.23,1,.32,1)",
		width: "100%",
		paddingLeft: 12,
	},
	inputFocused: {
		backgroundColor: "#FFFFFF",
		boxShadow:
			"0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2)",
		borderRadius: 8,
	},
	inputInput: {
		// vertical padding + font size from searchIcon
		//paddingLeft: `calc(1em + 30px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			//width: '20ch',
		},
		backgroundColor: "initial",
	},
	inputUnderline: {
		"&&&:before": {
			borderBottom: "none",
		},
		"&&:after": {
			borderBottom: "none",
		},
	},
	notificationBeforeClick: {
		fontWeight: 600,
	},
	notificationAfterClick: {
		fontWeight: 400,
	},
	headerBizName: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		//textTransform: "capitalize",
	},
});
