import gql from "graphql-tag";

export const providerByTokenQueryFragment = gql`
	fragment providerByTokenQueryFragment on Provider {
		_id
		first_name
		last_name
		legacy
		formatted_balance
		legacy_onboarded
		image {
			hash
			value
		}
		company_name
		restrict_access {
			reason
			value
		}
		all_preferences_set
		onboardingComplete
		alerts {
			_id
			viewed
			clicked
			message
			secondary_message
			type
			redirect_url
		}
	}
`;

export const providerServiceConfigurationsByTokenQueryFragment = gql`
	fragment providerServiceConfigurationsByTokenQueryFragment on ProviderServiceConfiguration {
		_id
		service {
			key
			name
		}
		boost_active
		preferences_set
		paused
	}
`;

export const getReferralConfigurationQueryFragment = gql`
	fragment getReferralConfigurationQueryFragment on ReferralConfiguration {
		REFERRAL_BONUS_AMOUNT
		REFERRAL_SPEND_THRESHOLD
	}
`;
